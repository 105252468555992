import { isNil } from 'lodash-es'
import React, { createContext, useState, useMemo, useContext } from 'react'

const SearchContext = createContext({})

const SearchProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false)

	const contextValue = useMemo(
		() => ({
			isOpen,
			toggleOpen: (newState) => {
				setIsOpen(isNil(newState) ? !isOpen : newState)
			},
		}),
		[isOpen]
	)

	return (
		<SearchContext.Provider value={contextValue}>
			{children}
		</SearchContext.Provider>
	)
}

const useSearchContext = () => {
	const { isOpen, toggleOpen } = useContext(SearchContext)
	return { isOpen, toggleOpen }
}

export { SearchProvider, SearchContext, useSearchContext }
