import React from 'react'
import './src/styles/main.scss'
import { SearchProvider } from './src/context/searchContext'


export const wrapRootElement = ({ element }) => {
  return (
    <SearchProvider>
      {element}
    </SearchProvider>
  )
}